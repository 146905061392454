<script setup lang="ts">
const props = withDefaults(defineProps<{
  text: string
  color?: 'white' | 'gray'
}>(), {
  color: 'gray',
})
</script>

<template>
  <div class="w-full flex flex-col items-center justify-center gap-3rem md:flex-row">
    <p class="relative w-full text-left text-1.1rem text-neutral-gray tracking-3px uppercase md:w-fit">
      {{ props.text }}
    </p>
    <div
      class="hidden h-1px w-full flex-1 lg:flex -translate-y-1/2" :class="{
        'bg-neutral-white': props.color === 'white',
        'bg-neutral-gray': props.color === 'gray',
      }"
    />
  </div>
</template>

<style scoped>

</style>
